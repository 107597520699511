<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="saveData"
        >
            Save
        </button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >
            Cancel
        </button>
    </section>
    <section class="uk-margin">
        <button
            class="uk-button uk-button-default uk-border-rounded uk-width-1-1 uk-text-bolder uk-flex uk-flex-middle uk-flex-center"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="addEducation()"
        >
            <img :src="`${images}/add.png`" alt="" width="20">
            <div class="uk-margin-small-left">Tambah Pendidikan</div>
        </button>
    </section>
    <section class="uk-margin">
      <section v-for="(dt, dKey) in form" :key="`d-${dKey}`">
        <div v-for="(edu, eKey) in details" :key="eKey">
          <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
            v-for="(ed, edKey) in edu"
            :key="edKey"
          >
            <div class="uk-width-1-2 uk-margin-auto-vertical">{{ ed.label }}</div>
            <div class="uk-width-1-2">
              <div class="uk-grid-collapse" uk-grid>
                <!-- DATE INPUT -->
                <div v-if="ed.type === 'date'" class="uk-width-expand">
                  <div>
                    <div class="uk-flex uk-flex-middle uk-flex-left">
                        <datetime
                            name="date"
                            id="date"
                            input-class="uk-input uk-border-rounded"
                            type="date"
                            format="dd LLL yyyy"
                            :max-datetime="datetime.now().toISODate()"
                            v-model="form[dKey].start_date"
                        ></datetime>
                        <span class="uk-margin-small-left uk-margin-small-right">-</span>
                        <datetime
                            name="date"
                            id="date"
                            input-class="uk-input uk-border-rounded"
                            type="date"
                            format="dd LLL yyyy"
                            :max-datetime="datetime.now().toISODate()"
                            :disabled="form[dKey].present"
                            v-model="form[dKey].end_date"
                        ></datetime>
                    </div>
                    <div class="uk-margin-small-top">
                        <label>
                        <input
                            v-model="form[dKey].present"
                            class="uk-checkbox"
                            type="checkbox"
                            @change="(form[dKey].end_date = null)"
                        > Masih menempuh pendidikan disini
                        </label>
                    </div>
                  </div>
                </div>
                <!-- SELECT INPUT -->
                <div v-else-if="ed.type === 'select'" class="uk-width-expand">
                  <multiselect
                      v-model="form[dKey][ed.value]"
                      placeholder="Select Pendidikan"
                      label="name"
                      name="education"
                      track-by="name"
                      :options="educations"
                      :loading="isLoading"
                      :internal-search="true"
                      :clear-on-select="false"
                  ></multiselect>
                </div>
                <!-- TEXT INPUT -->
                <div v-else class="uk-width-expand">
                  <input
                    v-model="form[dKey][ed.value]"
                    class="uk-input"
                    type="text"
                  >
                </div>
              </div>
              <div v-if="errorsD[dKey] && errorsD[dKey][ed.value]" class="uk-text-danger">{{ errorsD[dKey][ed.value] }}</div>
            </div>
          </div>
          <!-- Delete Button -->
          <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
          >
            <div class="uk-width-1-2" />
            <div class="uk-width-1-2">
              <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-expand">
                  <button
                      class="uk-button uk-button-default uk-border-rounded uk-margin-right"
                      style="border-color: #0ABAB5; color: #0ABAB5"
                      @click="form.splice(dKey, 1)"
                  >
                      Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr v-if="dKey + 1 < data.length">
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess } from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import {PREFIX_IMAGE} from '@/utils/constant';
import Multiselect from 'vue-multiselect';

export default {
    props: {
        data: {
            type: Array,
            required: true,
        }
    },
    components: { Datetime, Multiselect },
    data() {
        return {
            images: PREFIX_IMAGE,
            datetime: DateTime,
            isLoading: false,
            educations: [
                { name: "Pilih gelar:", _id: null }
            ],
            details: [
                [
                    { label: "Gelar", value: "education_level", type: "select" },
                    { label: "Institusi", value: "institution_name", type: "text" },
                    { label: "Bidang Studi", value: "majors", type: "text" },
                    { label: "Durasi Studi", value: "periode", type: "date" },
                ]
            ],
            form: this.$props.data,
            errorsD: [{}],
        };
    },

    mounted() {
        this.getEducation();
    },

    methods: {
        ...mapActions({
            getEducationLevels: 'option_data/getEducationLevels',
            patchEducation: 'mitra/patchEducation',
        }),

        async getEducation() {
            const educations = await this.getEducationLevels();
            this.educations = [ ...this.educations, ...educations ];
        },

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                educations: this.form.map(data => {
                    return {
                        "sort": data.sort,
                        "education_level_id": data.education_level._id,
                        "institution_name": data.institution_name,
                        "majors": data.majors,
                        "start_date": data.start_date,
                        "end_date": data.end_date,
                        "present": data.present
                    };
                })
            };

            await this.patchEducation({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },

        addEducation() {
            this.form.push({
                sort: this.form.length + 1,
                education_level: null,
                institution_name: null,
                majors: null,
                start_date: null,
                end_date: null,
                present: false
            });
        },

        validateData() {
            this.errorsD = [{}];
            let isValidated = true;
            
            this.form.forEach((data, dKey) => {
                if (!data.education_level) {
                    if (!this.errorsD[dKey]) {
                        this.errorsD[dKey] = { education_level: "gelar harus diisi" };
                    } else {
                        this.errorsD[dKey]["education_level"] = "gelar harus diisi";
                    }

                    isValidated = false;
                }

                if (!data.institution_name) {
                    if (!this.errorsD[dKey]) {
                        this.errorsD[dKey] = { institution_name: "institusi harus diisi" };
                    } else {
                        this.errorsD[dKey]["institution_name"] = "institusi harus diisi";
                    }

                    isValidated = false;
                }

                if (!data.majors) {
                    if (!this.errorsD[dKey]) {
                        this.errorsD[dKey] = { majors: "bidang studi harus diisi" };
                    } else {
                        this.errorsD[dKey]["majors"] = "bidang studi harus diisi";
                    }

                    isValidated = false;
                }

                if (!data.start_date) {
                    if (!this.errorsD[dKey]) {
                        this.errorsD[dKey] = { periode: "tanggal mulai harus diisi" };
                    } else {
                        this.errorsD[dKey]["periode"] = "tanggal mulai harus diisi";
                    }

                    isValidated = false;
                }

                if (!data.present && !data.end_date) {
                    if (!this.errorsD[dKey]) {
                        this.errorsD[dKey] = { periode: "tanggal berakhir harus diisi" };
                    } else {
                        this.errorsD[dKey]["periode"] = "tanggal berakhir harus diisi";
                    }

                    isValidated = false;
                }
            });

            return isValidated ? true : false;
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
