<template>
  <div>
    <section>
        <button
            v-if="$can('EDIT', 'worker-mitra')"
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="$emit('action', true)"
        >
            Edit
        </button>
    </section>
    <section class="uk-margin">
      <section v-for="(dt, dKey) in data" :key="`d-${dKey}`">
        <div v-for="(edu, eKey) in details" :key="eKey">
          <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
            v-for="(ed, edKey) in edu"
            :key="edKey"
          >
            <div class="uk-width-1-2">{{ ed.label }}</div>
            <div class="uk-width-1-2">
              <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-auto">:&nbsp;</div>
                <div v-if="ed.value === 'education_level'" class="uk-width-expand">{{ dt[ed.value].name }}</div>
                <div v-else-if="ed.value === 'periode'" class="uk-width-expand">
                  {{ formatter.dateFormatSlash(dt.start_date) }} - {{ dt.end_date ? formatter.dateFormatSlash(dt.end_date) : "Sekarang" }}
                </div>
                <div v-else>{{ dt[ed.value] }}</div>
              </div>
            </div>
          </div>
          <hr v-if="dKey + 1 < data.length">
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";

export default {
    data() {
        return {
            isLoading: false,
            details: [
                [
                    { label: "Gelar", value: "education_level" },
                    { label: "Institusi", value: "institution_name" },
                    { label: "Bidang Studi", value: "majors" },
                    { label: "Durasi Studi", value: "periode" },
                ]
            ],
            data: null,
            formatter: formatter,
        };
    },

    async mounted() {
        this.getMitraEducation();
    },

    methods: {
        ...mapActions({
            getEducation: 'mitra/getEducation',
        }),

        async getMitraEducation() {
            this.isLoading = true;
            const data = await this.getEducation(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result;
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
